import logo from "../../logo.svg";
import checked1 from "../imagedata/images/checked1.webp";
import point from "../imagedata/images/point.svg";
import loader from "../imagedata/images/loader.svg";
import nticon from "../imagedata/images/nt-icon.svg";
import hrt from "../imagedata/images/hrt.svg";
import star from "../imagedata/images/star.png";
import summes from "../imagedata/images/summes.svg";
import lazy_icon from "../imagedata/images/lazy-icon.svg";
import eater from "../imagedata/images/eater.svg";
import high from "../imagedata/images/high.svg";
import progress_bar from "../imagedata/images/progress_bar.svg";
import final_payment_attention from "../imagedata/images/final_payment_attention.png";
import intrmdt from "../imagedata/images/intrmdt-ico.svg";
import mobilebotm from "../imagedata/images/mobilebotm.svg";
import ellipse1 from "../imagedata/images/ellipse1.svg";
import ellipse2 from "../imagedata/images/ellipse2.svg";
import ellipse3 from "../imagedata/images/ellipse3.svg";
import ellipse4 from "../imagedata/images/ellipse4.svg";
import money_back from "../imagedata/images/money-back.webp";
import gift_icon from "../imagedata/images/gift-icon.webp";
import insta from "../imagedata/images/insta.png";
import credit_icon from "../imagedata/images/credit-icon.svg";
import pay_icon from "../imagedata/images/pay_icon.png";
import f1 from "../imagedata/images/f1.jpg";
import f2 from "../imagedata/images/f2.jpg";
import paypal_f from "../imagedata/images/paypal_f.png";
import ssl from "../imagedata/images/ssl.png";
import qstn_icon from "../imagedata/images/qstn-icon.svg";
import backicon from "../imagedata/images/backicon.svg";
import circle from "../imagedata/images/circle.svg";
import grapg from "../imagedata/images/grapg.svg";
import hooray from "../imagedata/images/hooray.png";
import mail from "../imagedata/images/mail.png";
import google_play from "../imagedata/images/google_play.png";
import finwt from "../imagedata/images/finwt.svg";
import three_point_5 from "../imagedata/images/3-5-star-rating.webp";
import star_4 from "../imagedata/images/4-star-rating.webp";
import star_5 from "../imagedata/images/5-star-rating.webp";
import secure_lock from "../imagedata/images/Lock.webp";
import usaqrcode from "../imagedata/images/usa-qrcode.png";
import preusaqrcode from "../imagedata/images/preusa-qrcode.png";
import apple_store from "../imagedata/images/apple-store.png";

export const common_icons = {
  logo: logo,
  checked1: checked1,
  point: point,
  loader: loader,
  nticon: nticon,
  hrt: hrt,
  star: star,
  summes: summes,
  lazy_icon: lazy_icon,
  eater: eater,
  high: high,
  progress_bar: progress_bar,
  final_payment_attention: final_payment_attention,
  finwt: finwt,
  intrmdt: intrmdt,
  mobilebotm: mobilebotm,
  ellipse1: ellipse1,
  ellipse2: ellipse2,
  ellipse3: ellipse3,
  ellipse4: ellipse4,
  money_back: money_back,
  gift_icon: gift_icon,
  insta: insta,
  credit_icon: credit_icon,
  pay_icon: pay_icon,
  f1: f1,
  f2: f2,
  paypal_f: paypal_f,
  ssl: ssl,
  qstn_icon: qstn_icon,
  backicon: backicon,
  circle: circle,
  grapg: grapg,
  hooray: hooray,
  mail: mail,
  google_play: google_play,
  three_point_5: three_point_5,
  star_4: star_4,
  star_5: star_5,
  secure_lock: secure_lock,
  usaqrcode: usaqrcode,
  preusaqrcode: preusaqrcode,
  apple_store: apple_store,
};
