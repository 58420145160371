import React, { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import moengage from "@moengage/web-sdk"; //npm install --save @moengage/web-sdk
import TagManager from 'react-gtm-module';


export default function Head() {


  const [country_c, setCountryC] = useState('us');
  ReactSession.setStoreType('localStorage');
  moengage.initialize({ app_id: '32IDOHIC6T5MUQWXILK1LZGE', debug_logs: 0 });

  useEffect(() => {

    const currentURL = window.location.href;
    const hostname = new URL(currentURL).hostname;
    let country_host = hostname.split('.')[0];


    if (!['usa', 'uk', 'aus', 'ca'].includes(country_host)) {
      country_host = 'usa';
    }

    if (country_host === 'usa') {
      country_host = 'us';
    }
    setCountryC(country_host);



    if (!ReactSession.get("head_title")) {
      ReactSession.set("head_title", "Ketobalanced")
    }

    let currentPage = new URL(window.location.href).pathname;
    if (currentPage === "/offers/thank-you.php" && localStorage.getItem('__orderconfirm') === null) {
      // console.log("refreshed");
    } else {
      // const script1 = document.createElement('script');
      // script1.innerHTML = ` (function(w,d,s,l,i){ w[l]=w[l]||[]; w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'}); var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:''; j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl; f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-KHDHLVW'); `;
      // document.head.appendChild(script1);

      // const script4 = document.createElement('noscript');
      // script4.innerHTML = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KHDHLVW" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
      // document.body.insertBefore(script4, document.body.firstChild);

      const tagManagerArgs = {
        gtmId: 'GTM-KHDHLVW', // Replace 'GTM-abc' with your actual Google Tag Manager container ID
      };

      TagManager.initialize(tagManagerArgs);

    }
  }, []);


  return (
    <>
      {ReactSession.get("head_title") && (
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{ReactSession.get("head_title")} </title>
            <script type="text/javascript" src="//cdn-4.convertexperiments.com/js/10041529-10046792.js"></script>

            {
              country_c == "us" ? (

               <></>
              ) : country_c == "ca" ? (

                <meta name="facebook-domain-verification" content="ktpxfhmo9n0bs3vfks15rb9h9gpu8e" />

              ) : country_c == "aus" ? (
                <></>
              ) : country_c == "uk" ? (
                <></>
              ) : (
                <></>
              )
            }

            {/* <meta property="og:type" content="Keto - tech blog" /> */}
            {/* <meta name="description" content="This is used for Height" /> */}
            {/* <meta property="og:site_name" content="code sample" /> */}
            {/* <meta name="twitter:card" content="summary" /> */}
            {/* <meta name="twitter:domain" content="code-sample.com" /> */}
            {/* <meta name="twitter:title" property="og:title" itemprop="name" content="keto meta tag dynamically." /> */}
            {/* <meta name="twitter:description" property="og:description" itemprop="description" content="actionplane " /> */}

          </Helmet>

        </HelmetProvider>

      )}
    </>

  )
}
