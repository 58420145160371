import React from 'react';
import logo from "../../logo.svg";

export default function Header() {
  return (
    <div className='logo1'>
      <img src={logo} width={55} height={26} alt='logo' />
    </div>
  )
}
