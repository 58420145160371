import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { monthly_result_images } from "../../config/imagedata/pages/monthly_result_images";
import { common_icons } from "../../config/imagedata/small_icons";
import SecurePolicy from "../common/SecurePolicy";


const OccasionResult = () => {
  ReactSession.setStoreType('localStorage');
  let history = useNavigate();
  const [nextFiveMonths, setNextFiveMonths] = useState([]);
  const [monthly_result, setmonthly_result] = useState([]);
  const [urlPre, setUrlPre] = useState("");

  useEffect(() => {

    if (window.location.pathname.split('/')[1] === "men") {
      setUrlPre("/men");
    }
    else if (window.location.pathname.split('/')[1] === "women") {
      setUrlPre("/women");
    }
    else if (window.location.pathname.split('/')[1] === "over40") {
      setUrlPre("/over40");
    }
    else {
      setUrlPre("");
    }

    getMonthData();
  }, []);

  const getMonthData = () => {

    setmonthly_result(ReactSession.get('kb_monthly_result'));


    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date();
    const currentMonthIndex = date.getMonth();

    // Calculate the next five months
    const calculatedNextMonths = Array.from({ length: 5 }, (_, index) => {
      const nextMonthIndex = (currentMonthIndex + index + 1) % 12;
      return months[nextMonthIndex];
    });
    setNextFiveMonths(calculatedNextMonths);

  }

  if (ReactSession.get('kb_monthly_result')) {
    return (
      <div>
        <Header />
        <div className='bodly'>
          <div className='tit'><h1>The one and only plan you’ll ever need to get in shape</h1></div>
          <div className='sutex'>According to the details provided, you will achieve your weight goal of</div>
          <div className="ocsn-rslt">
            <h2><u>{monthly_result.expected_result}</u></h2>
            <p>{monthly_result.sub_title_b}</p>
            <div className="ocsnresultgrph">
              <div className="grphwrap">
                <div className="ocsngrph"><img src={monthly_result_images['g1']} alt="Graph" width={300} height={119} loading="lazy" /></div>
                <div className="grphtglfst">
                  <div className="grphcntwrap">
                    <span>{ReactSession.get("type") === "kg" ? ReactSession.get('weight') : ReactSession.get('weight_1')}</span>
                    <span>{ReactSession.get('type')}</span>
                    <div className="trngl"></div>
                  </div>
                </div>
                <div className="grphtglscnd">
                  <p className="grph-2">Expected result</p>
                  <div className="grphcntwrap">
                    <span>{parseInt(monthly_result.estimated_no_of_month)}</span>
                    <span>{ReactSession.get('type')}</span>
                    <div className="trngl"></div>
                  </div>
                </div>
              </div>
              <div className="ocsnmnth">
                {
                  nextFiveMonths.map((mon, index) => (
                    <p key={index}>{mon}</p>
                  ))
                }
              </div>
            </div>
          </div>

          {/* <Link to={urlPre + '/kb-weekly-result.html'}><div className="sp11"><span className="necxbutton1 necxbuttontex" id="continue" >Continue</span></div></Link> */}
          <Link to={urlPre + '/email.html'}><div className="sp11"><span className="necxbutton1 necxbuttontex" id="continue" >Continue</span></div></Link>
          <SecurePolicy common_icons={common_icons} />
        </div>
      </div>

    );
  } else {
    return (
      <h1 align="center">Data not found...</h1>
    )
  }
}
export default React.memo(OccasionResult);